import { Invitation, InvitationAction } from '../invitation/invitation.model';

export enum MessageStatus {
  CREATED = 'created',
  SENT = 'sent',
  OPENED = 'opened',
}

export enum MailgunStatus {
  QUEUED = 'queued',
  DELIVERED = 'delivered',
  NOT_DELIVERED = 'not_delivered',
}

export class Message {
    public id: number;
    public temp_id?: string;
    public text: string;
    public author: number;
    public company: number;
    public room?: number;
    public is_waiting?: boolean;
    public created_at: string;
    public updated_at?: string;
    public invitation?: boolean;
    public status?: MessageStatus;
    public can_be_deleted?: boolean;

    constructor(text: string, author: number, company: number, room: number, is_waiting: boolean) {
        this.text = text;
        this.author = author;
        this.company = company;
        this.room = room;
        this.is_waiting = is_waiting;
    }
}


export class MessageTemplate {
    public id: number;
    public company: number;
    public text: string;
    public title: string;
}

export class InvitationLoadingData {
  action: InvitationAction;
}

export enum WSStatus {
  RETRIEVED = 'RETRIEVED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  CREATED = 'CREATED',
  FILTERED = 'FILTERED',
  ERROR = 2,
}

export enum WSErrorCode {
  TOKEN_EXPIRED = 'unauthorized__token_expired'
}

export class WSCloseStatus {
  code: number;
  message: string;
}

export class WsErrorData {
  description: string;
  action: InvitationAction;
}

export class WSMessage {
  status?: WSStatus;
  data?: any;
  action?: string;
}

export interface ChatWSResponse {
  date: string;
  items: Partial<Message>[] | Partial<Invitation>[];
}

